import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import * as de from 'dayjs/locale/de.js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as en from 'dayjs/locale/en.js';

import * as nl from 'dayjs/locale/nl.js';

dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// We have to actually load locales, otherwise they aren't shipped in the client bundle
dayjs.locale(nl);
dayjs.locale(de);

dayjs.tz.setDefault('Europe/Berlin');

export function formatDate(date: Date | string, format = 'DD.MM.YY'): string {
  return dayjs(date).format(format);
}

export function formatDateLocale(date: Date, language: string): string {
  return dayjs(date).locale(language).format('L');
}

export function getWeekdayLocale(date: Date, language: string): string {
  return dayjs(date).locale(language).format('dddd');
}

export function getMonthLocale(date: Date, language: string): string {
  return dayjs(date).locale(language).format('MMMM');
}

export function getCurrentTimezone(): string {
  return dayjs.tz.guess();
}

export function getHoursLocale(
  date: Date,
  language: string,
  withAppendix?: boolean,
): string {
  if (language.toLowerCase() == 'de') {
    const time = dayjs(date).locale(language).format('HH:mm');
    if (withAppendix) return time + ' Uhr';
    return time;
  } else {
    // Switch for more languages later, this is very basic at the moment. :)
    if (withAppendix) return dayjs(date).locale(language).format('hh:mm A');
    return dayjs(date).locale(language).format('hh:mm');
  }
}

export function getDaysTillNow(date: Date) {
  return dayjs().diff(formatDate(date, 'YYYY-MM-DD'), 'day');
}

export default dayjs;
